import { useState } from 'react';
import SLLogo from '../../assets/img/sl-logo.svg';
import Goldenthread from '../../assets/img/golden-thread.png';
import IconDownArrow from '../../assets/img/icon-downarrow.svg';
import { StyledFooter } from './styles';
import { H5, P } from 'components/ui/Typography';
import dayjs from 'dayjs';

export default function Footer() {
    const [showing, setShowing] = useState(false);
    const currentYear = dayjs().year();

    return (
        <StyledFooter>
            <div className="sl-footer-image-container">
                <img src={Goldenthread} className="sl-golden-thread-image" alt="alt text" width="252" height="280" />
            </div>

            <div className="container footer-container-cls">
                <div className="flex-container">
                    <div className="main-footer-logo-container">
                        <a href="/">
                            <img
                                src={SLLogo}
                                alt="Standard Life | Making Good Choices With Your Life Savings"
                                width="260"
                                height="42"
                            />
                        </a>

                        <div className="main-footer-icon-content">
                            <P>
                                <strong>Part of Phoenix Group</strong>
                            </P>
                        </div>
                    </div>

                    <div className="main-footer-section">
                        <H5>Help and Support</H5>
                        <ul className="main-footer-section-items">
                            <li>
                                <a href="https://www.standardlife.co.uk/help" id="Help">
                                    Contact us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="main-footer-policy-strip footer-cls">
                    <ul className="policy-strip-list">
                        <li>
                            <a href="https://www.standardlife.co.uk/help/accessibility" id="Accessibility">
                                {' '}
                                Accessibility
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.co.uk/legal" id=" Legal information">
                                {' '}
                                Legal information
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.co.uk/privacy" id="Privacy Policy">
                                {' '}
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.co.uk/cookies" id="Cookie Policy">
                                {' '}
                                Cookie Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="bg-paleGrey">
                <div className="container main-footer-provider-container">
                    <div className="main-footer-provider-accordion">
                        <section className="">
                            <button className="accordion-toggle" onClick={() => setShowing(!showing)}>
                                <span>
                                    <img className="up" src={IconDownArrow} alt="down-arrow" />
                                    Provider details
                                </span>
                            </button>

                            <div className={`panel ${showing ? 'show' : 'hide'}`}>
                                <div className="accordion-panel-box">
                                    <P>
                                        This website describes products and services provided by Phoenix Life Limited,
                                        trading as Standard Life, and members of the Phoenix Group.
                                    </P>

                                    <P>
                                        Phoenix Life Limited, trading as Standard Life, is registered in England and
                                        Wales (1016269) at 1 Wythall Green Way, Wythall, Birmingham, B47 6WG. Phoenix
                                        Life Limited is authorised by the Prudential Regulation Authority and regulated
                                        by the Financial Conduct Authority and the Prudential Regulation Authority.
                                    </P>

                                    <P>
                                        Phoenix Life Limited uses the Standard Life brand, name and logo, under licence
                                        from Phoenix Group Management Services Limited.
                                    </P>

                                    <P>
                                        You can{' '}
                                        <a
                                            href="https://www.standardlife.co.uk/legal#Company%20and%20regulation%20details"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            find out more information
                                        </a>{' '}
                                        about the companies providing the products and services.
                                    </P>

                                    <P>
                                        &copy; {currentYear} Phoenix Group Management Services Limited. All rights
                                        reserved.
                                    </P>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="main-footer-provider-copyright">
                        <P>&copy; {currentYear} Phoenix Group Management Services Limited. All rights reserved.</P>
                    </div>
                </div>
            </div>
            <div className="container main-footer-mobile">
                <P>&copy; {currentYear} Phoenix Group Management Services Limited. All rights reserved.</P>
            </div>
        </StyledFooter>
    );
}
